<template>
  <div>
    <!--Our-Services-->
    <section id="services" class="section-padding">
      <b-container>
        <div class="text-center">
          <h2>Admin <span>Area</span></h2>
        </div>
      </b-container>
      <b-container>
        <b-row>
          <b-col cols="2" class="text-left">
            <AddStock />
          </b-col>
          <b-col cols="2" class="text-left">
            <ValuationTool />
          </b-col>
          <b-col class="text-right">
            <span style="padding-right: 15px; font-size: 12pt;">{{ user.email }}</span>
            <b-button size="sm" variant="danger" @click="logOut">Logout</b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-5">
            <b-tabs content-class="mt-3">
              <b-tab title="Manage Stock" active><ManageStock /></b-tab>
              <b-tab title="API Usage" ><APIUsage /></b-tab>
            </b-tabs>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>

</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'AdminArea',
  components: {
    AddStock: () => import('../components/AddStock'),
    ManageStock: () => import('../components/ManageStock'),
    ValuationTool: () => import('../components/ValuationTool'),
    APIUsage: () => import('../components/APIUsage'),
  },
  computed: {
    ...mapState('user', ['user'])
  },
  mounted() {
    this.checkLoggedIn()
  },
  methods: {
    ...mapActions('user', ['signUserOut', 'checkLoggedIn']),
    logOut() {
      this.signUserOut()
    }
  }
}
</script>
